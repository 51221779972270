<template>
    <modal
        :adaptive="true"
        :show="true"
        height="auto"
        scrollable
        size="middle"
        @hide="$emit('setTimeHide')"
        class="modal-set-time"
    >
        <div slot="header">
            <h2 class="font-normal">
                Set 
                {{ value.value.toUpperCase() }}
            </h2>
        </div>
        <div slot="body">
            <modal-field class="mt-4 mb-4" label="Date" required>
                <date-picker :disable-past="false" v-model="event.time"></date-picker>
            </modal-field>
            <modal-field class label="Time" required>
                <div class="flex">
                    <select id="hour" name="hour" v-model="event.time_hour">
                        <option :key="'h' + hour" :value="hour" v-for="hour in hours">{{ hour }}</option>
                    </select>
                    <select id="minute" name="minute" v-model="event.time_minute">
                        <option
                            :key="'m' + minute"
                            :value="minute"
                            v-for="minute in minutes"
                        >{{ minute }}</option>
                    </select>
                </div>
            </modal-field>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <button
                    @click="$emit('setTimeHide')"
                    class="px-8 mr-4 btn-grey-outline"
                >Cancel</button>
                <button
                    :disabled="loading"
                    @click="save"
                    class="px-10 btn-primary"
                >Save</button>
            </div>
            <!-- <pre class="overflow-auto text-xs max-h-80">{{ consignment }}</pre> -->
        </div>
    </modal>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import DatePicker from '~/components/DatePicker'

export default {
    name: 'SetTimeModal',
    components: { DatePicker },
    props: {
        value: {
            type: [Number, String, Object],
            default: 0,
        }
    },
    data() {
        return {
            loading: false,
            event: {},
        }
    },
    methods: {
        async save() {
            this.loading = true;

            let event = { ...this.event };

            if (event.editOriginatedAt) {
                let originated_at = moment(event.originated_at)
                    .set({ hour: event.originated_at_hour, minute: event.originated_at_minute, second: 0 })
                    .format('YYYY-MM-DD HH:mm:ss')
                event.originated_at = originated_at;
            } else {
                event.originated_at = moment().format('YYYY-MM-DD HH:mm:ss');
            }

            let time = moment(event.time)
                .set({ hour: event.time_hour, minute: event.time_minute, second: 0 })
                .format('YYYY-MM-DD HH:mm:ss')

            let data = {};

            data[this.value.value] = time;

            await axios.patch(`${this.$apiUrl.consignments.base}/${this.value.id}`, data);

            this.$snotify.success(`Set ${this.value.value} successfully`);

            this.$emit('setTimeHide');
            this.$emit('refreshTable');

            this.loading = false;
        }
    },
    computed: {
        hours() {
            let hours = [];
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            for (let index = 0; index < 24; index++) {
                let hour = index;
                hours.push(zeroPad(hour, 2));
            }
            return hours;
        },
        currentQuarter() {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            let now = moment().format('mm');
            now = parseInt(now);
            if (now > 0 && now < 15) {
                return 15;
            }
            if (now >= 15 && now < 30) {
                return 30;
            }
            if (now >= 30 && now < 45) {
                return 45;
            }
            return zeroPad(0, 2);
        },
        currentHour() {
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            let now = moment().format('mm');
            let nowHour = moment().format('H');
            if (now > 50) {
                nowHour = parseInt(nowHour) + 1;
            }
            return zeroPad(nowHour, 2)
        },
        minutes() {
            let minutes = ['00', '15', '30', '45'];
            return minutes;
        }
    },
    created() {
        if (this.value.val) {
            this.event.time = moment(this.value.val)
            this.event.time_hour = moment(this.value.val).format('HH');
            this.event.time_minute = moment(this.value.val).format('mm');
        }
    }
}
</script>

<style lang="scss">
.modal-set-time {
    .modal__body {
        overflow: visible
    }
}
</style>
